import React from 'react';
import AuthPage from 'app/routes/AuthPage';
import {Helmet} from 'react-helmet-async';

const LoginPage = () => {
  const props = {
    type: 'login',
    headline: 'Mit deinem Konto anmelden',
    subtitle: 'Willkommen zurück',
    emailButtonLabel: 'Mit E-Mail anmelden',
    emailButtonLinkTo: '/auth/login/email',
    hintText: 'Noch kein FuPa-Konto? ',
    hintLinkedText: 'Registrieren',
    hintLinkTo: '/auth/signup',
  };
  const meta = [{name: 'description', content: 'Melde dich bei deinem FuPa-Konto an und präsentiere deinen Verein.'}];
  return (
    <>
      <Helmet title='Login' meta={meta}>
        <link rel='canonical' href='https://www.fupa.net/auth/login' />
      </Helmet>
      <AuthPage {...props} />
    </>
  );
};

export default LoginPage;
